import { StyleSheet, Text, Animated, View, Platform } from 'react-native';
import { useContext, useEffect, useRef, useState } from 'react';
import ThemeContext from '../Contexts/Theme';

export default function LetterBox({hint, attemptLetter}) {

  const duration = 500;
  const [side, setSide] = useState(0)
  const [progress, setProgress] = useState(new Animated.Value(0))
  const [rotation, setRotation] = useState(new Animated.ValueXY({ x: 50, y: 50 }))
  const [zoom, setZoom] = useState(new Animated.Value(0))

  const { theme } = useContext(ThemeContext)

  const _flipTo = (toValue) =>{
    Animated.parallel([
      Animated.timing(progress, {
        toValue: side === 0 ? 100 : 0,
        duration,
        useNativeDriver: true
      }),
      Animated.sequence([
        Animated.timing(zoom, {
          toValue: 100,
          duration: duration / 2,
          useNativeDriver: true
        }),
        Animated.timing(zoom, {
          toValue: 0,
          duration: duration / 2,
          useNativeDriver: true
        })
      ]),
      Animated.timing(rotation, {
        toValue,
        duration: duration,
        useNativeDriver: true
      })
    ]).start();
  }

  const flipY = () => {
    _flipTo({
      x: 50,
      y: side === 0 ? 100 : 50
    });
    setSide(side === 0 ? 1 : 0)
  }

  const getCardATransformation = ()=> {
    //0, 50, 100

    const sideAOpacity = progress.interpolate({
      inputRange: [50, 51],
      outputRange: [100, 0],
      extrapolate: "clamp"
    });

    const sideATransform = {
      opacity: sideAOpacity,
      zIndex: side === 0 ? 1 : 0,
      transform: [{ perspective: 800 }]
    };

    // cardA Y-rotation
    const aYRotation = rotation.y.interpolate({
      inputRange: [0, 50, 100, 150],
      outputRange: ["-180deg", "0deg", "180deg", "0deg"],
      extrapolate: "clamp"
    });
    sideATransform.transform.push({ rotateY: aYRotation });
    
    return sideATransform;
  }

  const getCardBTransformation = ()=> {
    
    const sideBOpacity = progress.interpolate({
      inputRange: [50, 51],
      outputRange: [0, 100],
      extrapolate: "clamp"
    });

    const sideBTransform = {
      opacity: sideBOpacity,
      zIndex: side === 0 ? 0 : 1,
      transform: [{ perspective: -1 * 800 }]
    };
    let bYRotation;

    if (Platform.OS === "ios") {
      // cardB Y-rotation
      bYRotation = rotation.y.interpolate({
        inputRange: [0, 50, 100, 150],
        outputRange: ["0deg", "180deg", "0deg", "-180deg"],
        extrapolate: "clamp"
      });
    } else {
      // cardB Y-rotation
      bYRotation = rotation.y.interpolate({
        inputRange: [0, 50, 100, 150],
        outputRange: ["0deg", "-180deg", "0deg", "180deg"],
        extrapolate: "clamp"
      });
    }
    sideBTransform.transform.push({ rotateY: bYRotation });
    
    return sideBTransform;
  }

  const prevHintRef=  useRef()
  useEffect(()=>{
    prevHintRef.current = hint
  })
  const prevHint = prevHintRef.current

  useEffect(()=>{
    if(hint!= prevHint && hint != "empty" && hint != null){
      flipY()
    }
    if(hint != prevHint && hint =="empty"){
      setSide(0)
      setProgress(new Animated.Value(0))
      setRotation(new Animated.ValueXY({ x: 50, y: 50 }))
      setZoom(new Animated.Value(0))
    }
  },[hint])

  var cardATransform = getCardATransformation()
  var cardBTransform = getCardBTransformation()


  return (
    <View style={{height: '100%', width: '15%', minWidth: 60, maxWidth: 90, margin: 5}} >
      <Animated.View style={[
        cardATransform,
        {borderColor: theme.border},
        styles.container, styles.nonHint]} >
        <Text style={[styles.text, {color: theme.text}]}>{attemptLetter}</Text>
      </Animated.View>
      <Animated.View style={[
        cardBTransform,
        styles.container, styles.hint,
        { borderColor: theme.border,
          backgroundColor:
          hint=="correct"? theme.correct : 
          hint =="misplaced"? theme.misplaced: 
          hint == "incorrect"? theme.incorrect: null}]} >
        <Text style={[styles.text, {color: theme.text}]}>{attemptLetter}</Text>
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    margin: 5,
    height: "90%",
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text:{
    fontSize: 28,
    fontWeight: "bold"
  },
  nonHint: {
    position: 'absolute',
    
  },
  hint: {
    backfaceVisibility: 'hidden'
  }
});
