import { useState } from 'react'
import Game from './src/Components/Game';
import Toast, {BaseToast} from 'react-native-toast-message'
import ThemeContext from './src/Contexts/Theme'
import SettingsContext from './src/Contexts/Settings'
import { theme } from './src/styles';
import AppLoading from 'expo-app-loading';
import { DEFAULT_SETTINGS } from './src/assets/constants'
import { getData } from './src/Utilities/Storage';
import { useKeepAwake } from 'expo-keep-awake'

import('./src/Services/Sentry').then(sentry=> sentry.initializeSentry())

export default function App() {
  useKeepAwake()
  const [currentTheme, setCurrentTheme] = useState(theme.lightTheme)
  const [settings, setSettings] = useState(DEFAULT_SETTINGS)
  const [isReady, setIsReady] = useState(false)

  const loadSettings = ()=>{
    getData("settings")
    .then(data=>{
      if(data){
        var settings = {}
        settings.maxAttempts = data.maxAttempts == null? DEFAULT_SETTINGS.maxAttempts: data.maxAttempts
        settings.wordLength = data.wordLength == null? DEFAULT_SETTINGS.wordLength: data.wordLength
        if(data.useDarkMode){
          settings.useDarkMode = true
          setCurrentTheme(theme.darkTheme)
        }else{
          settings.useDarkMode = false
          setCurrentTheme(theme.lightTheme)
        }
        setSettings(settings)

      }else{
        setSettings(DEFAULT_SETTINGS)
      }
      setIsReady(true)
    })

  }

  const toastConfig = {
    success: (props) => (
      <BaseToast     
        style={{ borderLeftWidth: 0}}
        text1Style={{fontSize: 16, textAlign: 'center'}}
        text2Style={{fontSize: 12, textAlign: 'center'}}
        {...props}
        />
    )
  }

  const toggleTheme = (useDark)=>{
    setCurrentTheme(useDark? theme.darkTheme: theme.lightTheme)
  }

  const editSettings = (newSettings)=>{
    setSettings(newSettings)
  }

  return (
    isReady? 
    <ThemeContext.Provider value={{theme: currentTheme, toggleTheme: toggleTheme}}>
      <SettingsContext.Provider value={{settings:
        {maxAttempts: settings.maxAttempts, wordLength: settings.wordLength}, 
        editSettings: editSettings}} > 
          <Game />
          <Toast 
            config={toastConfig}
            position={"top"}
          />
      </SettingsContext.Provider>
    </ThemeContext.Provider>:
    <AppLoading 
      startAsync={()=>loadSettings()}
      onFinish={()=>{}}
      onError={()=>{}}
    />
  );
}

export {SettingsContext}
