

export const colors = {
  "primary": "#ffc600",
  "secondary": "#ff0050",
  "background": "",
  "yellow": "#D4B900",
  "lightYellow": "#ffc600",
  "green": "#60992D",
  "lightGreen": "#22BB33",
  "darkWhite": "#ECECEC",
  "lightestGrey": "#DDDDDD",
  "lightGrey": "#AFAFAF",
  "darkGrey": "#C6C6C6",
  "darkestGrey": '#616161',
  "black": "#000000",
  "white": "#ffffff"
}

export const theme = {
  darkTheme: {
    name: "dark",
    primary: colors.primary,
    secondary: colors.secondary,   
    background: colors.black,
    contrastBackground: colors.darkestGrey,
    card: colors.lightGrey,
    border: colors.darkGrey,
    text: colors.white,
    incorrect: colors.darkestGrey,
    correct: colors.green,
    misplaced: colors.yellow
  },
  lightTheme: {
    name: "light",
    primary: colors.primary,
    secondary: colors.secondary,  
    background: colors.white,
    contrastBackground: colors.darkWhite,
    card: colors.lightestGrey,
    border: colors.lightGrey,
    key: colors.lightestGrey,
    text: colors.black,
    contrastText: colors.white,
    incorrect: colors.lightGrey,
    correct: colors.lightGreen,
    misplaced: colors.lightYellow

  }
}
