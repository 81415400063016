import { useContext, useState, useEffect } from 'react';
import { StyleSheet, Text, View, SafeAreaView, Modal, Switch, TouchableOpacity } from 'react-native';
import Constants from 'expo-constants';
import SettingsContext from '../Contexts/Settings';
import ThemeContext from '../Contexts/Theme'
import * as allStyles from '../styles';
import { storeData } from '../Utilities/Storage';
import Slider from '@react-native-community/slider';
export default function Settings({visible, closeSettings}){

  const {settings, editSettings} = useContext(SettingsContext)
  const [numAttempts, setNumAttempts] = useState(settings.maxAttempts)
  const [wordLength, setWordLength] = useState(settings.wordLength)
  const [modalVisible, setModalVisible] = useState(visible)
  const [useDarkMode, setUseDarkMode] = useState(false)
  const {theme, toggleTheme} = useContext(ThemeContext) 

  useEffect(()=>{
    setModalVisible(visible)
  }, [visible])

  const closeModal = ()=>{
    storeData("settings", {
      "maxAttempts": numAttempts,
      "wordLength": wordLength,
      "useDarkMode": useDarkMode
    })
    import('../Services/Sentry').then(
      sentry=>sentry.logEvent("Saved Settings", "Settings", {maxAttempts: numAttempts, wordLength: wordLength, useDarkMode: useDarkMode})
    )
    
    editSettings({
      maxAttempts: numAttempts,
      wordLength: wordLength
    })
    setModalVisible(false)
    closeSettings()
  }

  return (
    <Modal visible={modalVisible} presentationStyle='pageSheet'>
      <SafeAreaView style={[styles.container, {backgroundColor: theme.background}]}>
        <Text style={[styles.title, {color: theme.text}]}>SETTINGS</Text>
          <View style={styles.content}>
            <View style={[styles.controlRow, {borderTopWidth: 1}]}>
              <Text style={[styles.label, {color:theme.text}]}>Dark Mode: </Text>
              <Switch
                trackColor={{ false: allStyles.colors.darkestGrey, true: allStyles.colors.lightestGrey }}
                thumbColor={useDarkMode ? theme.secondary : '#f4f3f4'}
                ios_backgroundColor={allStyles.colors.darkerGrey}
                onValueChange={(value)=>{
                  setUseDarkMode(value)
                  toggleTheme(value)}}
                value={useDarkMode}
                />
            </View>
            <View style={styles.controlRow}>
              <Text style={[styles.label, {color:theme.text}]}>Word Length: </Text>

              <View style={styles.buttonGroup}>
                <TouchableOpacity style={[styles.button, wordLength != 3 ? styles.buttonUnselected : null]} onPress={() => setWordLength(3)}>
                  <Text style={[styles.buttonText, wordLength != 3 ? {color: allStyles.colors.darkestGrey} : {color: 'white'}]}>3 letters</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button, wordLength != 4 ? styles.buttonUnselected : null]} onPress={() => setWordLength(4)}>
                  <Text style={[styles.buttonText, wordLength != 4 ? {color: allStyles.colors.darkestGrey} : {color: 'white'}]}>4 letters</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={[styles.controlRow, styles.column]}>
              <Text style={[styles.label, {color:theme.text}]}>Number of guesses: </Text>
              <Text style={[styles.label, {color:theme.text}]}>{numAttempts}</Text>
              <Slider style={{width: "90%", height: 40}}
                thumbTintColor={theme.secondary}
                value={numAttempts} minimumTrackTintColor={theme.primary}
                step={1} minimumValue={4} maximumValue={8}
                onValueChange={(value)=>setNumAttempts(value)} />
            </View>
          </View>
        <TouchableOpacity style={styles.button} onPress={()=>{closeModal()}}>
          <Text style={[styles.buttonText, {color: allStyles.colors.white}]}>Close</Text>
        </TouchableOpacity>
        <Text style={styles.versionText}>{Constants.manifest.version}</Text>
      </SafeAreaView>
    </Modal>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  content:{
    marginBottom: 30,
    width: '100%',
  },
  controlRow:{
    padding: 20,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: allStyles.colors.lightestGrey
  },
  column:{
    flexDirection: 'column',
  },
  title:{
    fontSize: 30,
    fontWeight: "bold",
    marginTop: 50,
    marginBottom: 30
  },
  label:{
    fontSize: 18,
    padding: 8
  },
  buttonGroup:{
    flexDirection: 'row',
    width: '60%'
  },
  button:{
    flex: 1,
    maxHeight: 50,
    margin: 3,
    backgroundColor: allStyles.colors.secondary,
    padding: 15,
    width: 140,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonUnselected:{
    backgroundColor: allStyles.colors.lightestGrey
  },
  buttonText:{
    fontSize: 18
  },
  versionText:{
    color: allStyles.colors.lightGrey,
    marginTop: 60
  }
});
