import React, { Suspense, useContext, useEffect, useState } from 'react';
import { StyleSheet, Image, View, TouchableOpacity, SafeAreaView, 
  Text, Dimensions, StatusBar } from 'react-native';
import Toast from 'react-native-toast-message'
import Keyboard from './Keyboard';
import Grid from './Grid';
import Settings from './Settings';
import Help from './Help';
import {gameWords4L, allFourLetterWords, gameWords3L, allThreeLetterWords} from '../assets/words'
import ThemeContext from '../Contexts/Theme'
import SettingsContext from '../Contexts/Settings'
import { updateStats } from '../Utilities/CalculateStats';



export default function Game() {
  const Stats = React.lazy(() => import('./Stats'));


  const [correct, setCorrect] = useState([])
  const [incorrect, setIncorrect] = useState([])
  const [misplaced, setMisplaced] = useState([])
  const [currentGuess, setCurrentGuess] = useState(0)
  const [attempts, setAttempts] = useState([""])
  const [answer, setAnswer] = useState("")
  const [hints, setHints] = useState([])
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [helpVisible, setHelpVisible] = useState(false)
  const [statsVisible, setStatsVisible] = useState(false)
  const [logoImageDimensions, setLogoImageDimensions] = useState({height: 50, width: 120})  
  const [gameOver, setGameOver] = useState(false)
  const { settings:{maxAttempts, wordLength} } = useContext(SettingsContext)
  const { theme } = useContext(ThemeContext)
  
  useEffect(()=>{
    var screenWidth = Dimensions.get('window').width > 600 ? 600 : Dimensions.get('window').width
    setLogoImageDimensions({height: screenWidth/4, width: screenWidth/3})
    setLogoImageDimensions({height: 70, width: 300})
    setHelpVisible(true)
  },[])
  
  useEffect(()=>{
    startOver()
  }, [maxAttempts, wordLength])

  const startOver = () =>{
    setGameOver(false)
    setAttempts([""])
    var index, answer
    if(wordLength == 3){
      index = Math.floor(Math.random() * gameWords3L.length)
      answer = gameWords3L[index]
      
    }else{
      index = Math.floor(Math.random() * gameWords4L.length)
      answer = gameWords4L[index]
    }
    console.log(answer)
    import('../Services/Sentry').then(
      sentry=>sentry.logEvent("Starting new game", "Game", {answer: answer})
    )
    setAnswer(answer)
    setCorrect([])
    setIncorrect([])
    setMisplaced([])
    setCurrentGuess(0)
    setHints([])
  }

  const submitGuess = () => {
    
    if(attempts[currentGuess] == answer.toUpperCase()){
      //Submitted guess is correct
      import('../Services/Sentry').then(
        sentry=>sentry.logEvent("Guessed correctly", "Game", {guesses: currentGuess, wordLength: wordLength})
      )
      setGameOver(true)
      updateStats({won: true, guesses: currentGuess, wordLength: wordLength})
      var hintArray = Array(wordLength).fill("correct")
      var tmpHintArray = [...hints]
      tmpHintArray.push(hintArray)
      setHints(tmpHintArray)
      Toast.show({
        text1: "You did it!",
        text2: "Press to start a new game",
        autoHide: false,
        onPress: ()=>{startOver(), Toast.hide()}
      })
    }else if(attempts[currentGuess].length < wordLength){
      //Submitted guess is not long enough
      return
    }else if((!allFourLetterWords.includes(attempts[currentGuess]) && wordLength == 4) ||
      ((!allThreeLetterWords.includes(attempts[currentGuess]) && wordLength ==3))
    ){
      import('../Services/Sentry').then(
        sentry=>sentry.logEvent("Not a word", "Game", {guess: attempts[currentGuess]})
      )
      //Submitted guess is not a word
      Toast.show({
        text1: "Not a word in list",
        visibilityTime: 2000
      })
    }else{
      //calculate hints 
      var guess = attempts[currentGuess]
      var hint
      var hintArray = []
      var updatedIncorrect = []
      var updatedCorrect = []
      var updatedMisplaced = []
      var remainingAnswer = answer.toUpperCase()

      //Check for correct letters first, update hints and correct letter list
      for(var i=0;i<guess.length;i++){
        if(guess[i] == answer.charAt(i).toUpperCase()){
          hint="correct"
          updatedCorrect.push(guess[i])
          remainingAnswer = remainingAnswer.replace(guess[i],'')
        }else{
          hint = "empty"
        }
        hintArray[i] = (hint)
      }

      for(var i=0;i<guess.length;i++){
        if(hintArray[i] != "correct"){
          //Check for misplaced letters in the remaining answer that was not correct
          if(remainingAnswer.indexOf(guess[i]) >=0){
            remainingAnswer = remainingAnswer.replace(guess[i],'')
            hint="misplaced"
            //Check that letter is not already on correct list and updatedCorrect list
            if(correct.findIndex(l=>l==guess[i]) <0 && updatedCorrect.findIndex(l=>l==guess[i] <0)){
              updatedMisplaced.push(guess[i])
            }
          }else{
            //Check that letter is not already on correct or misplaced list, or updated correct or misplaced lists
            if(correct.findIndex(l=>l==guess[i] <0) && misplaced.findIndex(l=>l==guess[i]) <0 &&
                updatedCorrect.findIndex(l=>l==guess[i]) <0 && updatedMisplaced.findIndex(l=>l==guess[i]) <0){
              updatedIncorrect.push(guess[i])
            }
            hint = "incorrect"
          }
          hintArray[i] = (hint)
        }
        hint = "empty"
      }
      

      addCorrectLetters(updatedCorrect)
      addMisplacedLetters(updatedMisplaced)
      addIncorrectLetters(updatedIncorrect)

      var tmpHintArray = [...hints]
      tmpHintArray.push(hintArray)
      setHints(tmpHintArray)

      import('../Services/Sentry').then(
        sentry=>sentry.logEvent("Submitted Guess", "Game", {guess: attempts[currentGuess], hint: hintArray})
      )
      
      //Game is not over, add empty string for next guess
      if(currentGuess < maxAttempts-1){
        var tmpAttemptArray = [...attempts]
        tmpAttemptArray.push("")
        setAttempts(tmpAttemptArray)
        setCurrentGuess(currentGuess+1)
      }
    }

    if(currentGuess == maxAttempts-1 && 
      attempts[currentGuess] != answer.toUpperCase()){
      import('../Services/Sentry').then(
        sentry=>sentry.logEvent("Game Lost", "Game", {attempts: attempts, hints: hints, wordLength: wordLength, maxAttempts: maxAttempts})
      )
      setGameOver(true)  
      updateStats({won: false, guesses: currentGuess, wordLength: wordLength})
      Toast.show({
        text1: answer.toUpperCase(),
        visibilityTime: 6000,
        onPress: ()=>startOver()
      })
    }
  }

  const buttonPress = (key)=>{
    if(!gameOver){
      if(key == "enter"){
        submitGuess()
      }
      else if(key == "delete"){
        var tmpArray = [...attempts]
        tmpArray[currentGuess] = tmpArray[currentGuess].slice(0,-1)
        setAttempts(tmpArray)
      }
      else if(attempts[currentGuess].length <wordLength){
        var tmpArray = [...attempts]
        tmpArray[currentGuess] = tmpArray[currentGuess].concat(key)
        setAttempts(tmpArray)
      }
    }
  }
  const addIncorrectLetters = (letters)=>{
    var tmpArray = [...incorrect]
    tmpArray = tmpArray.concat(letters)
    setIncorrect(tmpArray)
  }

  const addMisplacedLetters = (letters)=>{
    var tmpArray = [...misplaced]
    tmpArray = tmpArray.concat(letters)
    setMisplaced(tmpArray)
  }

  const addCorrectLetters = (letters)=>{
    var tmpArray = [...correct]
    tmpArray = tmpArray.concat(letters)
    setCorrect(tmpArray)
    letters.forEach(l=>{
      tmpArray = [...misplaced]
      setMisplaced(tmpArray.filter(m=>m==l))
    })

  }

  const closeSettings = ()=>{
    setSettingsVisible(false)
  }

  const closeHelp = ()=>{
    setHelpVisible(false)
  }

  const closeStats = ()=>{
    setStatsVisible(false)
  }

  return (
    <SafeAreaView style={[styles.container, {
      backgroundColor: theme.background,
    }]}
    >
      <StatusBar 
        backgroundColor={theme.background} 
        barStyle={theme.name == 'dark' ? 'light-content': 'dark-content'}
      />
      <View style={[styles.header, {borderBottomWidth: 1, borderColor: theme.secondary}]}>
        <TouchableOpacity onPress={()=>setHelpVisible(true)} style={[styles.button, styles.headerButton]}>
          <Image source={require('../assets/help.png')} style={[styles.icon, 
            {tintColor: theme.primary}]}/>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>startOver()} style={[styles.button, styles.headerButton]}>
          <Image source={require('../assets/Refresh.png')} style={[styles.icon, 
            {tintColor: theme.primary}]}/>
        </TouchableOpacity>
        <View style={styles.logoContainer}>
          <Image source={require('../assets/logo.png')} style={[styles.logo, logoImageDimensions]} />
        </View>
        <TouchableOpacity onPress={()=>setStatsVisible(true)} style={[styles.button, styles.headerButton]}>
          <Image source={require('../assets/stats.png')} style={[styles.icon, 
            {tintColor: theme.primary}]}/>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>setSettingsVisible(true)} style={[styles.button, styles.headerButton]}>
          <Image source={require('../assets/settings.png')} style={[styles.icon, 
            {tintColor: theme.primary}]}/>
        </TouchableOpacity>
      </View>
      <Grid width={wordLength} height={maxAttempts} attempts={attempts} currentAttempt={currentGuess}
        answer={answer} hints={hints}/>
      <Keyboard buttonPress={buttonPress} incorrect={incorrect} correct={correct} misplaced={misplaced}/>
      <Settings visible={settingsVisible} closeSettings={closeSettings}/>
      <Help visible={helpVisible} closeHelp={closeHelp} />
      <Suspense fallback={<View />}>

        <Stats visible={statsVisible} closeStats={closeStats} />
      </Suspense>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header:{
    flex: 6,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
    paddingLeft: 6,
    paddingRight: 6
  },
  logoContainer:{
    flex: 6,
    paddingRight: 20,
    paddingLeft: 20,
    alignItems: 'center'
  },
  logo: {
    maxHeight: '90%',
    maxWidth: "90%"
  },
  button:{
    padding: 10
  },
  headerButton:{
    flex: 1
  },
  icon: {
    height: 25,
    width: 25
  }
});
