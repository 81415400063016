import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import LetterBox from './LetterBox';

export default function Grid({width, height, attempts, currentAttempt, hints }) {

  const [currentAttemptArray, setCurrentAttemptArray] = useState([])
  const resetGrid = ()=>{
    //fill a width x height array of blank characters
    var tmpArray = []
    var emptyArray = Array(width).fill('')
    for(var i=0;i<height; i++){
      tmpArray.push(emptyArray)
    }

    setCurrentAttemptArray(tmpArray)
  }

  useEffect(()=>{
    resetGrid
  },[])

  useEffect(()=>{
    //User typed letters, update array
    if(attempts.length> 0 && attempts[0] != ""){
      var guessWord = attempts[currentAttempt]
      if(guessWord.length <= width){
        var amountToFill = width - guessWord.length
        var filler = Array(amountToFill).fill('')
        var tmpArray = [...currentAttemptArray]
        tmpArray[currentAttempt] = guessWord.split('').concat(filler)
        setCurrentAttemptArray(tmpArray)
      }
    }else{
      resetGrid()
    }

  },[attempts])

  return (
    <View style={styles.container}>
      {currentAttemptArray.length>0 && 
        currentAttemptArray.map((guess, j) =>{
        //var guessArray = guess.split('')
        return(
          <View style={styles.gridRow} key={"row"+j}>
            {guess.map((currentLetter, i) =>{
              var hint = hints.length >j && hints[j].length>i ? hint = hints[j][i] :hint = "empty"
              return (
                <LetterBox attemptLetter={currentLetter} hint={hint} key={"attempt"+j+"letter"+i}/>
              )
            })}
          </View>
        )
      })
      }
    </View>

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 38,
    marginBottom: 15
  },
  gridRow:{
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  }
});
