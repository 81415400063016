import React from 'react'
import { elementsThatOverlapOffsets } from 'react-native/Libraries/Lists/VirtualizeUtils'
import { getData, storeData } from './Storage'


const defaultData = {
  numGamesPlayed: 0,
  numGamesWon: 0,
  maxStreak: 0,
  currentStreak: 0,
  guessDistribution3L: Array(8).fill(0),
  guessDistribution4L: Array(8).fill(0)
}

export const updateStats = ({won, guesses, wordLength})=>{
  getData("stats")
  .then((data)=>{
    if(data== null){
      data = defaultData
    }

    var numGamesPlayed = data.numGamesPlayed
    var numGamesWon = data.numGamesWon
    var maxStreak = data.maxStreak
    var currentStreak = data.currentStreak
    var guessDistribution3L = data.guessDistribution3L
    var guessDistribution4L = data.guessDistribution4L

    if(numGamesPlayed != null && typeof numGamesPlayed == "number"){
      numGamesPlayed = numGamesPlayed +1
    }else{
      numGamesPlayed = 1
    }

    if(numGamesWon != null && typeof numGamesWon == "number"){
      numGamesWon = won? numGamesWon+1: numGamesWon
    }else{
      numGamesWon = won ? 1:0
    }

    if(currentStreak != null && typeof currentStreak == "number"){
      currentStreak = won? currentStreak+1 : 0
    }else{
      currentStreak = won? 1:0
    }

    if(maxStreak != null && typeof maxStreak == "number"){
      maxStreak = currentStreak >= maxStreak? currentStreak : maxStreak
    }else{
      maxStreak = 0
    }

    if(wordLength == 3){
      guessDistribution3L[guesses] = guessDistribution3L[guesses] +1
    }else if(wordLength ==4){
      guessDistribution4L[guesses] = guessDistribution4L[guesses] +1
    }

    var updatedStats = 
    {
      numGamesPlayed: numGamesPlayed,
      numGamesWon: numGamesWon,
      maxStreak: maxStreak,
      currentStreak: currentStreak,
      guessDistribution3L: guessDistribution3L,
      guessDistribution4L: guessDistribution4L
    }

    storeData("stats", updatedStats)
  })
}


export const getStats = async ()=>{
  var data = await getData("stats")
  if(data){
    return data
  }else{
    return defaultData
  }
}