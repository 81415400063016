import { useContext } from 'react'
import { StyleSheet, Text, TouchableOpacity, Image, View } from 'react-native';
import ThemeContext from '../Contexts/Theme';

export default function Keyboard({incorrect, correct, misplaced, buttonPress}){
  
  const { theme } = useContext(ThemeContext)
  const firstRow = ['Q','W','E','R','T','Y','U','I','O','P']
  const secondRow = ['A','S','D','F','G','H','J','K','L']
  const thirdRow = ['Z','X','C','V','B','N','M']

  const keyPress = (key)=>{
    buttonPress(key)
  }


  const renderKey = (key)=>{
    var backgroundColor
    if(incorrect.findIndex(i=>key==i) >=0){
      backgroundColor = theme.incorrect
    }else if(correct.findIndex(c=>key==c)>=0){
      backgroundColor = theme.correct
    }else if(misplaced.findIndex(m=>key==m) >=0){
      backgroundColor = theme.misplaced
    }else{
      backgroundColor = theme.card
    }
    return (
      <TouchableOpacity onPress={()=>keyPress(key)} key={key}
          style={[styles.keyBox, 
            {backgroundColor: backgroundColor}
        ]}>
          <Text style={[styles.keyText, {color: theme.text}]}>{key}</Text>
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.keyboardContainer}>
      <View style={styles.keyboardContainer}>
        <View style={styles.keyRow}>
          {firstRow.map(renderKey)}
        </View>
        <View style={styles.keyRow}>
          {secondRow.map(renderKey)}
        </View>
        <View style={styles.keyRow}>
          <TouchableOpacity style={[styles.specialKeyBox, {backgroundColor: theme.card}]} 
            onPress={()=>keyPress('enter')}>
            <Text style={[styles.keyText, {color: theme.text}]}>ENTER</Text>
          </TouchableOpacity>
          {thirdRow.map(renderKey)}
          <TouchableOpacity style={[styles.specialKeyBox, {backgroundColor: theme.card}]} onPress={()=>keyPress('delete')}>
            <Image source={require('../assets/backspace.png')} style={[styles.keyIcon, {tintColor: theme.text}]} />
          </TouchableOpacity>
        </View>
      </View>
    
    </View>
  )

}

const styles = StyleSheet.create({
  keyboardContainer:{
    flex: 17
  },
  keyRow:{
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: '32%'
  },
  keyBox: {
    flex: 1,
    borderRadius: 5,
    margin: 3,
    alignItems: 'center',
    justifyContent: "center",
    maxWidth: '8%'
  },
  specialKeyBox:{
    flex: 1,
    borderRadius: 5,
    margin: 3,
    alignItems: 'center',
    justifyContent: "center",
    maxWidth: '14%'
  },
  keyIcon: {
    width: 25,
    height: 18
  },
  keyText:{
    fontWeight: "bold"
  }
});
