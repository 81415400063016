import { useState, useContext } from 'react';
import { StyleSheet, Text, View, SafeAreaView, Modal, TouchableOpacity } from 'react-native';
import ThemeContext from '../Contexts/Theme'
import { useEffect } from 'react';
import { colors } from '../styles';
import LetterBox from './LetterBox';

export default function Help({visible, closeHelp}){

  const [modalVisible, setModalVisible] = useState(visible)
  const {theme} = useContext(ThemeContext) 


  useEffect(()=>{
    setModalVisible(visible)
  }, [visible])

  const closeModal = ()=>{
    setModalVisible(false)
    closeHelp()
  }

  return (
    <Modal visible={modalVisible} presentationStyle='pageSheet' animationType='slide'>
      <SafeAreaView style={[styles.container, {backgroundColor: theme.background}]}>
        <Text style={[styles.title, {color: theme.text}]}>HOW TO PLAY</Text>
        <View style={styles.content}>
          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={[styles.label, {color:theme.text}]}>Guess the word in 4-8 tries.</Text>
            </View>
            <View style={styles.row}>
            <Text style={[styles.label, {color:theme.text}]}>Each guess must be a valid word. Hit the enter button to submit.</Text>
            </View>
            <View style={styles.row}>
            <Text style={[styles.label, {color:theme.text}]}>After each guess, the color of the tiles will change to show how close your guess was to the word.</Text>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={[styles.label, styles.bold, {color:theme.text}]}>Examples</Text>  
            <View style={styles.letterBoxRow}>
              <LetterBox hint={"correct"} attemptLetter={"T"}/>
              <LetterBox hint={"empty"} attemptLetter={"E"}/>
              <LetterBox hint={"empty"} attemptLetter={"A"}/>
              <LetterBox hint={"empty"} attemptLetter={"R"}/>
            </View>
            <View style={styles.row}>
              <Text style={[styles.label, {color:theme.text}]}>The letter T is in the word and in the right spot</Text>
            </View>
            <View style={styles.letterBoxRow}>
              <LetterBox hint={"empty"} attemptLetter={"M"}/>
              <LetterBox hint={"misplaced"} attemptLetter={"I"}/>
              <LetterBox hint={"empty"} attemptLetter={"L"}/>
              <LetterBox hint={"empty"} attemptLetter={"K"}/>
            </View>
            <View style={styles.row}>
              <Text style={[styles.label, {color:theme.text}]}>The letter I is in the word but in the wrong spot</Text>
            </View>
            <View style={styles.letterBoxRow}>
              <LetterBox hint={"empty"} attemptLetter={"P"}/>
              <LetterBox hint={"empty"} attemptLetter={"A"}/>
              <LetterBox hint={"incorrect"} attemptLetter={"S"}/>
              <LetterBox hint={"empty"} attemptLetter={"T"}/>
            </View>
            <View style={styles.row}>
              <Text style={[styles.label, {color:theme.text}]}>The letter S is not in the word</Text>
            </View>
          </View>
        </View>
        <View style={{flex: 1}}>
          <TouchableOpacity style={styles.button} onPress={()=>{closeModal()}}>
            <Text style={styles.buttonText}>Close</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </Modal>
  )
}
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1
  },
  content:{
    flex: 5
  },
  section:{
    borderTopWidth: 1, 
    borderColor: colors.lightestGrey,
    padding: '5%'
  },
  row:{
    paddingTop: 8,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    
  },
  letterBoxRow:{
    maxHeight: '18%',
    flexDirection: 'row'
  },
  title:{
    fontSize: 30,
    fontWeight: "bold",
    marginTop: 30,
    marginBottom: 20
  },
  label:{
    fontSize: 14
  },
  bold:{
    fontWeight: 'bold'
  },
  button:{
    backgroundColor: colors.secondary,
    padding: 15,
    width: 140,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonText:{
    color: 'white',
    fontSize: 18
  }
});
